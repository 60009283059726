import React from 'react'
import { Box } from 'rebass/styled-components'

import Layout from '../layouts'
import SEO from '../components/seo'
import Container from '../components/container'
import { OutlineLink } from '../components/button'

const NotFoundPage = (props) => (
  <Layout {...props} locale="en">
    <SEO title="How to order" />
    <Box py={4}>
      <Container maxWidth="780px">
        <h1>How to order</h1>

        <h2>If you are interested in any of our products, the process is simple:</h2>

        <ol>
          <li>
            Choose from our wide collection.
          </li>
          <li>
            Place an order on product detail.
          </li>
          <li>
            Pay via PayPal and look forward to our shipment.
          </li>
        </ol>

        <Box mb={5}>
          <OutlineLink to="/collection/">Check out our collection</OutlineLink>
        </Box>
      </Container>
    </Box>
  </Layout>
)

export default NotFoundPage
